import React, { useState, useReducer } from "react";
import PrintContext from "./PrintContext";
import { printReducer, TOGGLE_PRODUCT_TO_PRINT } from "./reducers";

function GlobalState(props: { children: React.ReactNode; }) {
  const [printState, dispatch] = useReducer(printReducer, { itemsToPrint: localStorage.getItem("itemsToPrint") ? JSON.parse(localStorage.getItem("itemsToPrint")) : [] });


  const toggleProductToPrint = product => {
    dispatch({ type: TOGGLE_PRODUCT_TO_PRINT, product: product });
  };


  return (
    <PrintContext.Provider
      value={{
        itemsToPrint: printState.itemsToPrint,
        toggleProductToPrint,
      }}
    >
      {props.children}
    </PrintContext.Provider>
  );
}

export default GlobalState;
