
import jwt from 'jwt-decode'
export const isTrader = () => {
    const user = jwt(localStorage.getItem('token'));
    return (user && user['roles'] && user['roles'].includes("TRADER"));
}

export const isAdmin = () => {
    const user = jwt(localStorage.getItem('token'));
    return (user && user['roles'] && user['roles'].includes("ADMIN"));
}

export const hasWordPressWebsite = () => {
    const user = jwt(localStorage.getItem('token'));
    return (user && user['hasPrivateWebSite'])
}

export const connectedUserPhoneNumber = () => {
    const user = jwt(localStorage.getItem('token'));
    return (user && user['phoneNumber'] ? user['phoneNumber'] : "");
}