// @ts-nocheck
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { styled } from 'baseui';
import Button from '../../components/Button/Button';
import {
  Grid,
  Row as Rows,
  Col as Column,
} from '../../components/FlexBox/FlexBox';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import {Ticket} from '../../components/Ticket/Ticket';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { Header, Heading } from '../../components/WrapperStyle';
import Fade from 'react-reveal/Fade';
import ProductCard from '../../components/ProductCard/ProductCard';
import NoResult from '../../components/NoResult/NoResult';
import { CURRENCY, LOGIN } from '../../settings/constants';
import Placeholder from '../../components/Placeholder/Placeholder';
import { TypesWithLabel, StatusWithLabel } from '../../utils/types';
import CheckoutContext from '../../context/checkout/CheckoutContext';
import PrintContext from '../../context/print/PrintContext';
import { FormLabel } from '../../components/FormFields/FormFields';
import { ProductsLayoutContext } from '../../routes';
import { Button as BaseButton, SHAPE } from 'baseui/button';
import { useDrawerDispatch } from '../../context/DrawerContext';
import { GET_ME } from '../Profile/Profile';
import { isTrader } from '../../utils/helpers';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import QrReader from 'react-qr-reader';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal';
import {Notification} from 'baseui/notification';
import { useHistory, useLocation } from 'react-router-dom';

export const ProductsRow = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '25px',
  backgroundColor: $theme.colors.backgroundF7,
  position: 'relative',
  zIndex: '1',
  '@media only screen and (max-width: 767px)': {
    marginLeft: '-7.5px',
    marginRight: '-7.5px',
    marginTop: '15px',
  },
}));

export const Col = styled(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = styled(Rows, () => ({
  '@media only screen and (min-width: 768px) and (max-width: 991px)': {
    alignItems: 'center',
  },
}));

export const ProductCardWrapper = styled('div', () => ({
  height: '100%',
}));

export const LoaderWrapper = styled('div', () => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexWrap: 'wrap',
}));

export const LoaderItem = styled('div', () => ({
  width: '25%',
  padding: '0 15px',
  marginBottom: '30px',
}));

export const GET_PRODUCTS = gql`
  query getProducts(
    $type: Type
    $offset: Int
    $trader: ID
    $provider: ID
    $status: String
    $ref: String
    $limit: Int
    $createdByMe: Boolean
    $providedByMe: Boolean
  ) {
    products(
      type: $type
      offset: $offset
      trader: $trader
      provider: $provider
      status: $status
      ref: $ref
      limit: $limit
      createdByMe: $createdByMe
      providedByMe: $providedByMe
    ) {
      items {
        id
        ref
        name
        description
        image
        gallery {
          url
        }
        type
        price
        unit
        salePrice
        discountInPercent
        categories {
          id
          title
        }
        quantity
        brand
        size
        color
        providerId
        members {
          id
          status
          colors
          size
        }
        agreement {
          traderPercentage
          returnPeriod
          storagePeriod
        }
        createdAt
        quality {
          id
          label
        }
        status
        statusHistory {
          date
          status
          customer {
            firstName
            lastName
            phoneNumber
          }
        }
        provider {
          firstName
          lastName
          phoneNumber
        }
        isVisibleInStore
        priceIsVisible
        trader {
          firstName
          lastName
          phoneNumber
          brand
        }
      }
      total
      hasMore
    }
  }
`;

const typeSelectOptions = [
  { value: 'grocery', label: 'Grocery' },
  { value: 'women-cloths', label: 'Women Cloths' },
  { value: 'bags', label: 'Bags' },
  { value: 'makeup', label: 'Makeup' },
];
const priceSelectOptions = [
  { value: 'highestToLowest', label: 'Highest To Lowest' },
  { value: 'lowestToHighest', label: 'Lowest To Highest' },
];

const typesOptions = Object.keys(TypesWithLabel).map(key => ({ value: key, label: TypesWithLabel[key] }))
const statusOptions = Object.keys(StatusWithLabel).map(key => ({ value: key, label: StatusWithLabel[key] }))

export default function Products() {
  const [clients, setClients] = useState([])
  const [provider, setProvider] = useState([]);
  const [showNotif, setShowNotif] = useState(true);
  const [max, setMax] = useState(12)
  const { data: meData } = useQuery(GET_ME)
  const notification = useRef();
  const history=useHistory()
  const location = useLocation()
  const [status, setStatus] = useState([statusOptions[0]]);
  const [isOpened, setIsOpened] = React.useState(false);
  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const [tabs, setTabs] = React.useState([])
  const [activeItems, setActiveItems] = React.useState([])
  const [alertPrint, setAlertPrint] = React.useState('')
  const [printableItems, setPrintableItems]= React.useState([]);

  useEffect(() => {
    var arr = []
    for(var i = 1 ; i <= 40 ; i++) {
      arr.push(i)
    }
    setTabs(arr);
    setActiveItems(arr.map((element) => false))
  }, [])

  useEffect(() => {
    if (meData && meData.me && meData.me.relations && meData.me.privateRelations) {
      const { relations, privateRelations } = meData.me
      const createContactSelectItem = ({ id, phoneNumber, firstName, lastName }) => ({ value: id, label: `${phoneNumber ? phoneNumber : ''} ${firstName ? firstName : ''} ${lastName ? lastName : ''}` })
      setClients([...relations.map(createContactSelectItem)])
    }
  }, [meData])

  const [ref, setRef] = useState("")
  const dispatch = useDrawerDispatch();
  const openDrawer = useCallback(
    () => dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'PRODUCT_FORM' }),
    [dispatch]
  );

  const { endReached, setEndReached } = useContext(ProductsLayoutContext)
  const [isLoading, setIsLoading] = useState(false)

  const context = useContext(CheckoutContext)
  const contextPrint = useContext(PrintContext)
  const {itemsToPrint} = useContext(PrintContext);

  const queryDetails = useQuery(GET_PRODUCTS, {
     fetchPolicy: "network-only", 
     variables: { 
       status: status[0].value, 
       limit:12, 
       createdByMe:true,
       createdByMe: isTrader() ? true : undefined,
       providedByMe: !isTrader() ? true : undefined
      } 
    });
  const { data, error, refetch, fetchMore } = queryDetails;
  useEffect(() => {
    setIsLoading(false)
  }, [data])
  const [loadingMore, toggleLoading] = useState(false);
  const [type, setType] = useState([typesOptions[0]]);
  // const [status, setStatus] = useState([statusOptions[0]]);
  const [priceOrder, setPriceOrder] = useState([]);
  const [search, setSearch] = useState([]);
  const [positions, setPositions] = useState([]);
  // Result of the scan
  // Modal
  const [isOpen, setIsOpen] = useState(false);
  // const textInput = useRef();

  useEffect(() => {
    if (endReached && data && data.products && data.products.hasMore) {
      loadMore()
      setEndReached(false)
    }
  }, [endReached])

  // Check if notification has seen before
  useEffect(() => {
    if(localStorage.getItem('notifications')){
      var localStorageNotifications =  localStorage.getItem('notifications').split(',')
      if(localStorageNotifications.indexOf(notification.current.props.id) != -1) {
        setShowNotif(false)
      };
    } else {
      setShowNotif(true)
    }
  }, [])

  let typingTimer = null;

  useEffect(() => {
    return () => {
      clearTimeout(typingTimer);
    }
  }, [])

  // filter on change router when i click in a notification

  useEffect(() => {
    let refFromUrl = location.search.substring(location.search.lastIndexOf('ref=') + 5, location.search.lastIndexOf('&'));
    let statusFromUrl = location.search.substring(location.search.lastIndexOf('status=') + 7)

    if(refFromUrl) {
      data && queryDetails && queryDetails.refetch && queryDetails.refetch({
        ref : refFromUrl,
        createdByMe: isTrader() ? true : undefined,
        providedByMe: !isTrader() ? true : undefined
      })
      setRef(refFromUrl)
    }
    if(statusFromUrl) {
      data && queryDetails && queryDetails.refetch && queryDetails.refetch({
        status : statusFromUrl,
        createdByMe: isTrader() ? true : undefined,
        providedByMe: !isTrader() ? true : undefined
      })
      setStatus([{ value: statusFromUrl, label: StatusWithLabel[statusFromUrl] }]);
      
    }
  }, [data, location])



  if (error) {
    return <div>Error! {error.message}</div>;
  }
  function loadMore() {
    toggleLoading(true);
    fetchMore({
      variables: {
        offset: max,
      },
      updateQuery: (prev, { fetchMoreResult }) => {        
        setMax(max + 12)
        toggleLoading(false);
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          products: {
            __typename: prev.products.__typename,
            items: [...prev.products.items, ...fetchMoreResult.products.items],
            hasMore: fetchMoreResult.products.hasMore,
            total: fetchMoreResult.products.total,
          },
        });
      },
    });
  }

  function handleCategoryType({ value }) {
    setType(value);
    if (value.length) {
      refetch({
        type: value[0].value,
        createdByMe: isTrader() ? true : undefined,
        providedByMe: !isTrader() ? true : undefined
      });
    } else {
      refetch({
        type: undefined,
        createdByMe: isTrader() ? true : undefined,
        providedByMe: !isTrader() ? true : undefined
      });
    }
  }

  async function handleStatusChange({ value }) {
    setIsLoading(true)
    setMax(12)
    let res = null
    try {
      if (value.length) {
        setStatus(value);
        res = await refetch({
          status: value[0].value,
          createdByMe: isTrader() ? true : undefined,
          providedByMe: !isTrader() ? true : undefined
        });
      } else {
        setStatus([{value:null}])
        res = await refetch({
          status: null,
          createdByMe: isTrader() ? true : undefined,
          providedByMe: !isTrader() ? true : undefined
        });
      }
      if(res?.loading == false) {
        setIsLoading(false)
      }  
    } catch (error) {
      console.log(error);
    }
  }
  async function handleProviderChange({ value }) {
    setIsLoading(true)
    setProvider(value);
    let res = null
    try {
      if (value.length) {
        isTrader() ? (
          res = await refetch({
            provider: value[0].value,
            createdByMe: isTrader() ? true : undefined,
            providedByMe: !isTrader() ? true : undefined
          })
        ) : (
          res = await refetch({
              trader: value[0].value,
              createdByMe: isTrader() ? true : undefined,
              providedByMe: !isTrader() ? true : undefined
            })
          )
      } else {
        isTrader() ? (
          res = await refetch({
            provider: undefined,
            createdByMe: isTrader() ? true : undefined,
            providedByMe: !isTrader() ? true : undefined
          })
        ) : (
          res = await refetch({
              trader: undefined,
              createdByMe: isTrader() ? true : undefined,
              providedByMe: !isTrader() ? true : undefined
            })
          )
      }
      if(res?.loading == false) {
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error); 
    }
  }


  const handleRefChange = (e) => {
    setIsLoading(true)
    history.replace({
      search: "",
    })
    const value = e.target.value
    setRef(value)
    clearTimeout(typingTimer);
    typingTimer = setTimeout(async() => {
      try {
        const res = await refetch({
          ref: value,
          createdByMe: isTrader() ? true : undefined,
          providedByMe: !isTrader() ? true : undefined
        })
        if(res?.loading == false) {
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  }
  const cleanRef = () => {
    history.replace({
      search: "",
    })
    setRef("")
    refetch({
      ref: "",
      createdByMe: isTrader() ? true : undefined,
      providedByMe: !isTrader() ? true : undefined
    })
  }

  // QRCode reader result

  function handleScan(data) {
    if (data) {
      setRef(data)
      refetch({
        ref: data,
        createdByMe: isTrader() ? true : undefined,
        providedByMe: !isTrader() ? true : undefined
      })
      setIsOpen(false);
    }
  }
  function handleError(err){
    console.error(err)
  }
  
  // Modal for QRCode reader

  function close() {
    setIsOpen(false); 
  }

  function openModal() {
    navigator.mediaDevices.getUserMedia({
      video: true
    })
    setIsOpen(true)
  }
  // Handle notification
  const changeStatus = () => {  
    var localStorageNotifications =  localStorage.getItem('notifications') ? localStorage.getItem('notifications').split(',') : []
    localStorageNotifications.push(notification.current.props.id)
    localStorage.setItem('notifications', localStorageNotifications);
  }
const selectSquare = (e, index) => {
  setAlertPrint('');
  const newActiveItems = [...activeItems]
  newActiveItems[index] = !activeItems[index]
  setActiveItems(newActiveItems)
  let position = {top: e.target.dataset.top, left: e.target.dataset.left}
  let indexPosition = -1;
  if(positions.length > 0) {
    positions.map((pos, i) => {
      if(pos.top == position.top && pos.left == position.left) {
        indexPosition=i;
      }
    })
    if(indexPosition == -1){
      setPositions([...positions, position]);
    } else {
      let newPositions = positions;
      newPositions.splice(indexPosition, 1)
      setPositions(newPositions);
    }

  } else {
    setPositions([...positions, position]);
  }
  let newItems = [...itemsToPrint];
  console.log(positions.length);
  
  let forPrint = newItems.slice(0, positions.length + 1)
  
  setPrintableItems(forPrint);

}
  // Create and position squares to define the print area
const squares = tabs.map((value, index) => {
  let left = 0;
  let top = Math.floor(index / 4 ) * 29.7;
  if((index % 4 == 0)) {
    left = 0;
  } else if((index % 4 == 1)) {
    left = 52.5;
  } else if((index % 4 == 2)) {
    left = 52.5 * 2;
  } else if((index % 4 == 3)) {
    left = 52.5 * 3;
  }
  return <div id={`flexGridItem-${index}`} className={`flexGridItem ${activeItems[index] === true? 'active': ''}`} data-top={`${top}mm`} data-left={`${left}mm`} onClick={(e) => selectSquare(e, index)}>{value}</div>
})
function closeModal() {
  setIsOpened(false); 
  setIsModalOpened(false)
  setAlertPrint('');
  setActiveItems([]);
  setPositions([])
  setPrintableItems([])

}
function printModal() {
  if(positions.length > 0){
    window.print();
    printableItems.map(item => contextPrint.toggleProductToPrint(item))
    setIsOpened(false); 
    setIsModalOpened(false);
    setActiveItems([]);
    setPositions([]);
    setAlertPrint('');
    setPrintableItems([])
  } else {
    setAlertPrint("Selectionner au moins une zone d'impression")
  }
}
  return (
    <>
      {
        isTrader() &&
        <BaseButton
          shape={SHAPE.round}
          style={{ position: 'absolute', zIndex: 2, right: 30, bottom: 20 }}
          onClick={openDrawer}
        >
          <span style={{ fontSize: 40 }}> + </span>
        </BaseButton>
      }
      <Grid fluid={true}>
      {showNotif && <Notification 
      ref={notification} 
      id="notificationProducts"
      closeable 
      onClose={changeStatus}
      >
         {isTrader() ? 'Tous les articles existant dans votre boutique' : 'La liste de vos articles deposés'}
      </Notification>}
      <br/>
        <Row>
          <Col md={12}>
            <Header style={{ marginBottom: 15 }}>
              <Col md={2} xs={12}>
              <Heading>Filtrer</Heading>
              </Col>
              <Col md={10} xs={12}>
                <Row>
                  <Col md={3} xs={12}>
                    <FormLabel>Etat</FormLabel>
                    <Select
                      options={statusOptions}
                      labelKey='label'
                      valueKey='value'
                      value={status}
                      searchable={false}
                      onChange={handleStatusChange}
                    />
                  </Col>
                  <Col md={3} xs={12}>
                    <FormLabel>{isTrader() ? 'Déposant(e)' : 'Dépôt-vente'} </FormLabel>
                    <Select
                      options={clients}
                      labelKey='label'
                      valueKey='value'
                      placeholder={isTrader() ? 'Mes déposants' : 'Choisissez'}
                      value={provider}
                      onChange={handleProviderChange}
                      searchable
                    />
                  </Col>
                  <Col md={3} xs={12} >
                    <FormLabel>Ref</FormLabel>
                      <div style={{display:"flex"}}>
                        <div style={{position:"relative", height:"100%"}}>
                          <Input type="text" value={ref} onChange={handleRefChange} style={{display:"inline-block", height:"100%"}} isClearable/>
                          <div style={{display:"inline-block", border:"none", position:"absolute", right:"0%", top:"30%"}} onClick={() => cleanRef()}><svg data-baseweb="icon" viewBox="0 0 24 24" aria-label="Clear value" role="button" title="Clear value" size="16" className="al d9 e2 db ce ba"><title>Clear value</title><path fillRule="evenodd" clipRule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58173 16.4183 4 12 4C7.58173 4 4 7.58173 4 12C4 16.4183 7.58173 20 12 20ZM10.0303 8.96967C9.73743 8.67679 9.26257 8.67679 8.96967 8.96967C8.67676 9.26257 8.67676 9.73743 8.96967 10.0303L10.9393 12L8.96967 13.9697C8.67676 14.2626 8.67676 14.7374 8.96967 15.0303C9.26257 15.3232 9.73743 15.3232 10.0303 15.0303L12 13.0607L13.9697 15.0303C14.2626 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2626 15.0303 13.9697L13.0607 12L15.0303 10.0303C15.3232 9.73743 15.3232 9.26257 15.0303 8.96967C14.7374 8.67679 14.2626 8.67679 13.9697 8.96967L12 10.9393L10.0303 8.96967Z"></path></svg></div>
                        </div>
                        <Button style={{display:"inline-block", height:"45px", padding:"10px"}} onClick={() => openModal(true)}>Scan</Button>
                      {/* Modal to select the printing area */}
                      <Modal
                          onClose={close}
                          isOpen={isOpen}
                          overrides={{
                              Root: {
                                  style: { 
                                    zIndex: 5,
                                  }
                              },
                            }}
                      >
                        <ModalHeader>
                            Scanner un QRCode
                        </ModalHeader>
                        <ModalBody>
                        <QrReader
                          delay={300}
                          onError={handleError}
                          onScan={handleScan}
                          style={{ width: '100%'}}
                            
                        />
                        </ModalBody>
                        <ModalFooter className="no-print">
                          <ModalButton kind="tertiary" onClick={close}> Annuler  </ModalButton>
                        </ModalFooter>
                      </Modal>
                      </div>
                  </Col>
                  <Col md={3} xs={12} id="modalSquares">
                    <br/>
                    {itemsToPrint.length > 0 && <>
                    <Button style={{display:"block", height:"45px", padding:"10px", width:"100%"}} onClick={() => {setIsOpened(true); setIsModalOpened(true);}}>Imprimer</Button>
                    <Modal
                    onClose={closeModal}
                    isOpen={isModalOpened}
                    overrides={{
                        Root: {
                            style: { 
                              zIndex: 5,
                              opacity: 0,
                              '@media print' : {
                                opacity: 1,
                              }
                            }
                        },
                      }}
                    >
                      <ModalBody className="mbdoy">
                         <div>
                        {printableItems.map((itemToPrint, index) => <Ticket data={itemToPrint} index={index} positions={positions} name ={`${itemToPrint.provider.phoneNumber} - ${itemToPrint.provider.firstName ? itemToPrint.provider.firstName : ''} ${itemToPrint.provider.lastName ? itemToPrint.provider.lastName : ''} - ${itemToPrint.provider.brand ? itemToPrint.provider.brand : ''}`}/>)}
                        </div>
                      </ModalBody>
                      <ModalFooter className="no-print">
                      <ModalButton kind="tertiary" onClick={closeModal}> Annuler  </ModalButton>
                      <ModalButton onClick={() => printModal()}>Imprimer</ModalButton> 
                        <Modal
                        className="no-print"
                        onClose={closeModal}
                        isOpen={isOpened}
                        overrides={{
                            Root: {
                                style: { 
                                  zIndex: 6,
                                  '@media print' : {
                                    zIndex: 3,
                                  }
                                }
                              },
                          }}
                        >
                          <ModalBody>
                            <div className="flexGrid no-print">
                              {squares}
                            </div>
                          </ModalBody>
                          <ModalFooter  className="no-print">
                        {itemsToPrint.length > positions.length && <p>Selectionner {itemsToPrint.length - positions.length} zone(s) d'impression</p>}
                        {itemsToPrint.length == positions.length && <p>Vous pouvez imprimer votre tickets</p>}
                        {itemsToPrint.length < positions.length && <p>Déselectionner ({itemsToPrint.length - positions.length}) zone d'impression</p>}
                          {alertPrint && <span style={{color: 'red', float: 'left'}}>{alertPrint}</span> }
                            <ModalButton kind="tertiary" onClick={closeModal}> Annuler  </ModalButton>
                            <ModalButton onClick={() => printModal()}>Imprimer</ModalButton> 
                          </ModalFooter>
                        </Modal>
                        </ModalFooter>
                        </Modal>
                    </>
                    }
                  </Col>
                </Row>
              </Col>
            </Header>
            {
              isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                  <CustomSpinner />
                </div>
              )
            }
            <Row>
              {data ? (
                data.products && data.products.items.length !== 0 ? (
                  data.products.items.map((item: any, index: number) => {
                    return (
                      <Col
                        md={4}
                        lg={3}
                        sm={6}
                        xs={12}
                        key={index}
                        style={{ margin: '15px 0' }}
                      >
                        <Fade bottom duration={800} delay={index * 10}>
                          <ProductCard
                            title={item.name}
                            weight={item.unit}
                            image={item.image}
                            currency={CURRENCY}
                            price={item.price}
                            salePrice={item.salePrice}
                            discountInPercent={item.discountInPercent}
                            data={item}
                          />
                        </Fade>
                      </Col>
                    )
                  })
                ) : (
                    <NoResult />
                  )
              ) : (
                  <LoaderWrapper>
                    <LoaderItem>
                      <Placeholder />
                    </LoaderItem>
                    <LoaderItem>
                      <Placeholder />
                    </LoaderItem>
                    <LoaderItem>
                      <Placeholder />
                    </LoaderItem>
                    <LoaderItem>
                      <Placeholder />
                    </LoaderItem>
                  </LoaderWrapper>
                )}
            </Row>
            {/* <div style={{ display: 'flex', padding: 10, justifyContent: 'center' }}>
              {
                loadingMore && data.products.hasMore && <CustomSpinner />
              }
            </div> */}
            {data && data.products && data.products.hasMore && (
              <Row>
                <Col
                  md={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button onClick={loadMore} isLoading={loadingMore}>
                    Charger plus
                </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Grid>
    </>
  );
}