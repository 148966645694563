export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const TOGGLE_PRODUCT = "TOGGLE_PRODUCT";
export const RESET = "RESET";

const addProductToCart = (product, state) => {
    console.log("adding product", product);
    const items = [...state.items, product]
    localStorage.setItem("items", JSON.stringify(items)) 
    return {
        ...state,
        items
    }
};

const removeProductFromCart = (productId, state) => {
    console.log("remove product: " + productId);
    const items = state.items.filter(item => item.id !== productId)
    localStorage.setItem("items", JSON.stringify(items)) 
    return {
        ...state,
        items
    }
};

const toggleProduct = (product, state) => {
    console.log("toggling product", product);
    const productExistsAlready = state.items.find(item => item.id === product.id)
    if (productExistsAlready) {
        const items = state.items.filter(item => item.id !== product.id)
        localStorage.setItem("items", JSON.stringify(items)) 
        return {
            ...state,
            items
        }
    }
    const items = [...state.items, product]
    localStorage.setItem("items", JSON.stringify(items)) 
    return {
        ...state,
        items
    }
};

const reset = (state) => {
    const items = []
    localStorage.setItem("items", JSON.stringify(items)) 
    return {
        ...state,
        items
    }
};

export const shopReducer = (state, action) => {
    switch (action.type) {
        case ADD_PRODUCT:
            return addProductToCart(action.product, state);

        case REMOVE_PRODUCT:
            return removeProductFromCart(action.productId, state);

        case TOGGLE_PRODUCT:
            return toggleProduct(action.product, state);

        case RESET:
            return reset(state);

        default:
            return state;
    }
};
