import React, { useState, useReducer } from "react";
import CheckoutContext from "./CheckoutContext";
import { shopReducer, ADD_PRODUCT, REMOVE_PRODUCT, TOGGLE_PRODUCT, RESET } from "./reducers";

function GlobalState(props) {
  const [checkoutState, dispatch] = useReducer(shopReducer, { items: localStorage.getItem("items") ? JSON.parse(localStorage.getItem("items")) : [] });

  const addProductToCart = product => {
    dispatch({ type: ADD_PRODUCT, product: product });
  };

  const removeProductFromCart = productId => {
    dispatch({ type: REMOVE_PRODUCT, productId: productId });
  };

  const toggleProduct = product => {
    dispatch({ type: TOGGLE_PRODUCT, product: product });
  };

  const reset = () => {
    dispatch({ type: RESET });
  };

  return (
    <CheckoutContext.Provider
      value={{
        items: checkoutState.items,
        addProductToCart,
        removeProductFromCart,
        toggleProduct,
        reset
      }}
    >
      {props.children}
    </CheckoutContext.Provider>
  );
}

export default GlobalState;
