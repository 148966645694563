import * as React from 'react';
import { Button } from 'baseui/button';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';

const ConfirmUpdateStatusModal = ({ isOpen, item, onConfirm, onCancel, text }) => {

    return (
        <React.Fragment>
            <Modal
                onClose={onCancel}
                isOpen={isOpen}
                overrides={{
                    Root: {
                        style: { zIndex: 99 }
                    }
                }}
            >
                <ModalHeader>Confirmation</ModalHeader>
                <ModalBody>
                    {text}
                </ModalBody>
                <ModalFooter>
                    <ModalButton kind="tertiary" onClick={onCancel}>
                        Annuler
          </ModalButton>
                    <ModalButton onClick={onConfirm}>Confirmer</ModalButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default ConfirmUpdateStatusModal