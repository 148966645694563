const isInProduction = () => {
    return (process.env.NODE_ENV === "production")
}
export const configs = {
    bucketName: 'depostyle',
    dirName: 'images', /* optional */
    region: 'eu-west-3',
    accessKeyId: 'AKIAJG44PJN7NYFDAEOQ',
    secretAccessKey: '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',
  }

const development = {
    REACT_APP_UPLOAD_URL: "https://depostyle-uploader.depostyle.com",
    REACT_APP_API_URL: "https://server-cluster01.depostyle.com/graphql",
    REACT_APP_API_URL_WS: "wss://server-cluster01.depostyle.com/graphql",
    CLOUD: "aws" // cloudinary
}

const production = {
    REACT_APP_UPLOAD_URL: "https://depostyle-uploader.depostyle.com",
    REACT_APP_API_URL: "https://server-cluster01.depostyle.com/graphql",
    REACT_APP_API_URL_WS: "wss://server-cluster01.depostyle.com/graphql",
    CLOUD: "aws"// cloudinary
}
 
export default isInProduction() ? production : development
