export const honorTitles = {
  MR: 'Monsieur',
  MRS: 'Madame',
  MS: 'Mademoiselle'
}

export const roles = {
  TRADER: 'Vous voulez commercialiser des articles',
  PROVIDER: 'Vous voulez fournir des articles',
  CUSTOMER: 'Vous voulez acheter des articles'
}

export const depositRoles = {
  PROVIDER: 'Déposant',
  TRADER: 'Dépôt-vente'
}