import { isTrader } from "./helpers";

export const TypesWithLabel = {
  ALL: 'Tous',
  WOMAN_FASHION: 'Mode Femmes',
  MAN_FASHION: 'Mode Hommes',
  HOME: 'Maison',
  CHILDREN: 'Enfants',
};

export const StatusWithLabel = {
  IN_STORE: 'Dans le magasin',
  DELIVERED: 'Réservé',
  BOUGHT: 'Vendu',
  PAID: 'Payé',
  BLOCKED: 'Bloqué',
  RETRIEVED: 'Retiré',
  ORDRED: 'Commandé'
};

export const OrderStatusWithLabel = {
  processing: "en cours",
  cancelled: "annulé",
  validate: " à valider"
}
