import React from 'react'
import { Logo, LogoImage } from '../Layout/Topbar/Topbar.style'
import { Link, useLocation, Redirect, useHistory } from 'react-router-dom'
import Logoimage from '../../image/depostyle_sbs.svg'
import { Table } from 'baseui/table-semantic';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS } from '../Products/Products';
import moment from 'moment';
import { GET_USER } from '../ProductForm/requests';
import { GET_ME } from '../Profile/Profile';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import { Avatar } from 'baseui/avatar';
import { useStyletron } from 'baseui';
import { expandBorderStyles } from 'baseui/styles';
import { FaInfoCircle, FaArrowLeft } from 'react-icons/fa'



export default function Receipt() {
    interface State {
        traderId: string,
        providerId: string,
        status: string,
        date: string
    }
    // const { state } = useLocation()
    const locationData: { state: State } = useLocation()
    const history =  useHistory()
    const { state } = locationData
    const COLUMNS = ['Ref', 'Libellé', 'Marque', 'Date de dépôt', 'Part du déposant(e)'];
    // @ts-ignore
    const { data, error, refetch, fetchMore, loading } = useQuery(GET_PRODUCTS, { fetchPolicy: "network-only", variables: { trader: state && state.traderId, provider: state && state.providerId, status: state && state.status, limit: 1000 } });

    const { data: traderData } = useQuery(GET_ME)

    const DATA = data && data.products && data.products.items && data.products.items.filter(item => {
        if (item.statusHistory && item.statusHistory.length) {
            const lastUpdate = item.statusHistory[0].date
            if (moment(lastUpdate).isSame(moment(state.date.toString(), "DD/MM/YYYY").format("YYYY-MM-DD"), 'day')) {
                return true
            }
        }
    }).map((item) => {
        const priceAfterDiscount = item.salePrice
        const providerPart = priceAfterDiscount * (1 - (item.agreement.traderPercentage / 100))
        return ([item.ref, item.name, item.brand, moment(item.createdAt).format('DD/MM/YYYY'), providerPart?.toFixed(3)])
    })

    if (!state) {
        return <Redirect
            to="/"
        />
    }

    const fullName = `${traderData?.me?.firstName ? traderData.me.firstName : ''} ${traderData?.me?.lastName ? traderData.me.lastName : ''}`
    const firstItem = data && data.products && data.products.items && data.products.items[0]

    return (
        <>
        <br/>
        <div className="no-print">
            <FaArrowLeft size={30} style={{marginLeft: 20, cursor: "pointer"}} onClick={() => history.goBack()} />
        </div>
            <div style={{ padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Avatar
                        overrides={{
                            Avatar: {
                                style: ({ $theme }) => ({
                                    borderTopLeftRadius: $theme.borders.radius100,
                                    borderTopRightRadius: $theme.borders.radius100,
                                    borderBottomRightRadius: $theme.borders.radius100,
                                    borderBottomLeftRadius: $theme.borders.radius100,
                                }),
                            },
                            Root: {
                                style: ({ $theme }) => ({
                                    borderTopLeftRadius: $theme.borders.radius100,
                                    borderTopRightRadius: $theme.borders.radius100,
                                    borderBottomRightRadius: $theme.borders.radius100,
                                    borderBottomLeftRadius: $theme.borders.radius100,
                                }),
                            },
                        }}
                        name={traderData?.me?.brand ? traderData.me.brand : fullName}
                        size="scale1400"
                        src={traderData?.me?.photo ? traderData.me.photo : ''}
                    />
                    <h2> {(state && state.status == "IN_STORE" ? "Reçu de dépôt" : (state && state.status == "PAID" ? "Reçu de réglement" : ""))} {moment(state.date.toString(), "DD/MM/YYYY").format("DD-MM-YYYY")} </h2>
                    <h2> {traderData && traderData.me && traderData.me.brand} </h2>

                </div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <p><b>Déposant(e):</b> {firstItem && firstItem.provider.firstName} {firstItem && firstItem.provider.lastName}</p>
                    <p><b>Tel Déposant:</b> {firstItem && firstItem.provider.phoneNumber}</p>
                </div>

                <br />
                {
                    loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CustomSpinner />
                        </div>
                    ) : (
                            <>
                                <div>
                                    <Table columns={COLUMNS} data={DATA} overrides={{
                                        Table: {
                                            style: {
                                                borderColor: 'black'
                                            }
                                        }
                                    }} />
                                </div>

                            </>
                        )
                }
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h2>Total:</h2>
                    <h2> {data && data.products && data.products.items && DATA.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue[4]) , 0).toFixed(3)} TND </h2>
                </div>
                <br />
                <div>
                    <p> <b>Tel Boutique:</b>  {firstItem && firstItem.trader.phoneNumber}</p>
                </div>
                <FaInfoCircle color="#17a2b8" />
                <p style={{ display: "inline", marginLeft: 8 }}> Une remise peut être effectuée après un accord avec le déposant(e)</p>

                <br />
                <FaInfoCircle color="#17a2b8" />
                <p style={{ display: "inline", marginLeft: 8 }}> Vous pouvez suivre l'état de vos dépôts en allant sur <span style={{ fontWeight: "bold" }}>https://dashboard.depostyle.com</span> </p>
            </div>
        </>
    )
}


