import React, { useContext, useState } from 'react';
import {
  ProductCardWrapper,
  ProductImageWrapper,
  ProductInfo,
  SaleTag,
  DiscountPercent,
  Image,
  ProductTitle,
  ProductWeight,
  ProductMeta,
  OrderID,
  ProductPriceWrapper,
  ProductPrice,
  DiscountedPrice,
} from './ProductCard.style';
import { useDrawerDispatch } from '../../context/DrawerContext';
import { Button, SHAPE, KIND, SIZE } from 'baseui/button';
import Ic from 'baseui/icon/filter';
import { ProductIcon, LongArrowLeft, SiteSettings } from '../AllSvgIcon';
import CheckoutContext from '../../context/checkout/CheckoutContext';
import PrintContext from '../../context/print/PrintContext';
import ConfirmUpdateStatusModal from '../../containers/ProductForm/ConfirmUpdateStatusModal';
import { useMutation } from '@apollo/client';
import { MUTATE_ITEM_STATUS } from '../../containers/Checkout/requests';
import { useSnackbar } from 'notistack';
import { GET_PRODUCTS } from '../../containers/Products/Products';
import { FaTrash, FaQrcode } from 'react-icons/fa'
import { StatusWithLabel } from '../../utils/types';
import { isTrader } from '../../utils/helpers';
import ItemDetailsModal from '../IemDetailsModal/ItemDetailsModal';
import OrdersDetails from '../../containers/Orders/OrdersDetails';
type ProductCardProps = {
  title: string;
  image: any;
  weight?: string;
  currency?: string;
  description?: string;
  price: number;
  salePrice?: number;
  orderId?: number;
  discountInPercent?: number;
  data: any;
};

const ProductCard: React.FC<ProductCardProps> = ({
  title,
  image,
  weight,
  price,
  salePrice,
  discountInPercent,
  currency,
  data,
  orderId,
  ...props
}) => {
  const [itemDetailsModalIsOpen, setItemDetailsModalIsOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [confirmStatusUpdateModalIsOpen, setConfirmStatusUpdateModalIsOpen] = useState(false)
  const [confirmBlockStatusUpdateModalIsOpen, setConfirmBlockStatusUpdateModalIsOpen] = useState(false)
  const [identif, setIdentif] = useState(null)
  const context = useContext(CheckoutContext)
  const contextPrint = useContext(PrintContext)
  const dispatch = useDrawerDispatch();
  const openDrawer = React.useCallback(
    () =>
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'PRODUCT_UPDATE_FORM',
        data: data,
      }),
    [dispatch, data]
  );

  const openModal = () => {
    setItemDetailsModalIsOpen(true)
  }
  const setSettings = (id) => {
    setOpen(true)
    setIdentif(id)
  }

  const { enqueueSnackbar } = useSnackbar()
  const [mutateItemStatus, { loading: mutateStatusLoading }] = useMutation(MUTATE_ITEM_STATUS, {
    onError(error) {
      console.log({ error })
      let errorMessage = (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message) || 'Erreur'
      errorMessage = errorMessage.split(':')[0]
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      })
    },
    update(_, { data }) {
      console.log('mutateItemStatus', { data });
      if (data.mutateItemStatus) {
        enqueueSnackbar("Opération réussie", {
          variant: 'success'
        })
      }
    },
    refetchQueries: [{ query: GET_PRODUCTS, variables: { status: "IN_STORE" } }, { query: GET_PRODUCTS, variables: { status: "DELIVERED" } }, { query: GET_PRODUCTS, variables: { status: "BLOCKED" } }]
  })

  let customPrice = price
  let customSalePrice = salePrice

  if (!isTrader()) {
    customPrice = Number((price * (1 - data?.agreement?.traderPercentage / 100))?.toFixed(3))
    customSalePrice = Number((salePrice * (1 - data?.agreement?.traderPercentage / 100))?.toFixed(3))
  }

  return (
    <>
      <ItemDetailsModal isOpen={itemDetailsModalIsOpen} item={data} onCancel={() => setItemDetailsModalIsOpen(false)} />
      <ProductCardWrapper
        {...props}
        className="product-card"
      // onClick={openDrawer}
      >
        <div onClick={isTrader() ? openDrawer : openModal}>
          <ProductImageWrapper>
            <Image url={image} className="product-image" />
            {discountInPercent && discountInPercent !== 0 ? (
              <>
                <SaleTag>Sale</SaleTag>
                <DiscountPercent>{discountInPercent}% Off</DiscountPercent>
              </>
            ) : null}
          </ProductImageWrapper>
          <ProductInfo>
            <ProductTitle>{title}</ProductTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ProductWeight>Ref {data.ref} </ProductWeight>
              <ProductWeight> {StatusWithLabel[data.status]} </ProductWeight>
            </div>
            {/* <ProductWeight>{weight}</ProductWeight> */}
            <ProductMeta>
              <ProductPriceWrapper>
                <ProductPrice>
                  {customSalePrice && customSalePrice !== 0 ? customSalePrice : customPrice} TND
                {/* {currency} */}
                </ProductPrice>

                {discountInPercent && discountInPercent !== 0 ? (
                  <DiscountedPrice>
                    {customPrice} TND
                    {/* {currency} */}
                  </DiscountedPrice>
                ) : null}
              </ProductPriceWrapper>
              <OrderID>{orderId}</OrderID>
            </ProductMeta>
          </ProductInfo>
        </div>

        {isTrader() &&
          <div style={{ padding: 10, height: 60 }}>
            {
              data.status === "IN_STORE" &&
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    shape={SHAPE.round}
                    kind={KIND.minimal}
                    onClick={() => setConfirmBlockStatusUpdateModalIsOpen(true)}
                  >
                    <FaTrash />
                  </Button>
                  <Button
                    shape={SHAPE.round}
                    kind={context.items.find(item => item.id === data.id) ? KIND.primary : KIND.secondary}
                    onClick={() => context.toggleProduct(data)}
                  >
                    <ProductIcon></ProductIcon>
                  </Button>
                </div>
                <ConfirmUpdateStatusModal
                  isOpen={confirmBlockStatusUpdateModalIsOpen}
                  item={{}}
                  onConfirm={() => {
                    mutateItemStatus({
                      variables: {
                        items: [data.id],
                        action: "BLOCK"
                      },
                      // refetchQueries: [{ query: GET_PRODUCTS, variables: { status: "BLOCKED" } }]
                    })
                    setConfirmBlockStatusUpdateModalIsOpen(false)
                  }}
                  onCancel={() => setConfirmBlockStatusUpdateModalIsOpen(false)}
                  text="Veuillez confirmer le blockage de l'article"
                />
              </>
            }
            {
              data.status === "DELIVERED" &&
              <>
                <Button
                  size={SIZE.mini}
                  shape={SHAPE.pill}
                  onClick={() => setConfirmStatusUpdateModalIsOpen(true)}
                >
                  <LongArrowLeft />
                  <span style={{ marginLeft: 6 }}>Retourner au magasin</span>
                </Button>

                <ConfirmUpdateStatusModal
                  isOpen={confirmStatusUpdateModalIsOpen}
                  item={{}}
                  onConfirm={() => {
                    mutateItemStatus({
                      variables: {
                        items: [data.id],
                        action: "RETURN"
                      },
                      // refetchQueries: [{ query: GET_PRODUCTS, variables: { status: "RETRIEVED" } }]
                    })
                    setConfirmStatusUpdateModalIsOpen(false)
                    // closeDrawer();
                  }}
                  onCancel={() => setConfirmStatusUpdateModalIsOpen(false)}
                  text="Veuillez confirmer le retour de l'article au magasin"
                />
              </>
            }
            {
              data.status === "ORDRED" &&
              <>
                {/* <Button
                  size={SIZE.mini}
                  shape={SHAPE.pill}
                  onClick={() => setSettings(data.id)}
{ isTrader() &&
      <div style={{ padding: 10, height: 60 }}>
        {
          data.status === "IN_STORE" &&
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                shape={SHAPE.round}
                kind={KIND.minimal}
                onClick={() => setConfirmBlockStatusUpdateModalIsOpen(true)}
              >
                <FaTrash />
              </Button>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                shape={SHAPE.round}
                kind={contextPrint.itemsToPrint.find(itemToPrint => itemToPrint.id === data.id) ? KIND.primary : KIND.secondary}
                onClick={() => contextPrint.toggleProductToPrint(data)}
              >
              <FaQrcode  />
              </Button>
              <span style={{margin:5}}></span>
              <Button
                shape={SHAPE.round}
                kind={context.items.find(item => item.id === data.id) ? KIND.primary : KIND.secondary}
                onClick={() => context.toggleProduct(data)}
              >
                <ProductIcon></ProductIcon>
              </Button>
              </div>
            </div>
            <ConfirmUpdateStatusModal
              isOpen={confirmBlockStatusUpdateModalIsOpen}
              item={{}}
              onConfirm={() => {
                mutateItemStatus({
                  variables: {
                    items: [data.id],
                    action: "BLOCK"
                  },
                  // refetchQueries: [{ query: GET_PRODUCTS, variables: { status: "BLOCKED" } }]
                })
                setConfirmBlockStatusUpdateModalIsOpen(false)
              }}
              onCancel={() => setConfirmBlockStatusUpdateModalIsOpen(false)}
              text="Veuillez confirmer le blockage de l'article"
            />
          </>
        }

                >
                 
                  <span style={{ marginLeft: 6 }}>voir commande</span>
                </Button> */}
                {console.log("id ", data.id)}
                <a href='/orders' style={{ textDecoration: "none" }}> <Button
                  size={SIZE.mini}
                  shape={SHAPE.pill}
                  onClick={() => setSettings(data.id)}
                >
                  {/* <LongArrowLeft /> */}
                  <span style={{ marginLeft: 6 }}>voir commande</span>
                </Button></a>

                {/* <OrdersDetails isOpen={open} onClose={() => { setOpen(false) }} id={identif}/> */}




                {/* <ConfirmUpdateStatusModal
              isOpen={confirmStatusUpdateModalIsOpen}
              item={{}}
              onConfirm={() => {
                mutateItemStatus({
                  variables: {
                    items: [data.id],
                    action: "RETURN"
                  },
                  // refetchQueries: [{ query: GET_PRODUCTS, variables: { status: "RETRIEVED" } }]
                })
                setConfirmStatusUpdateModalIsOpen(false)
                // closeDrawer();
              }}
              onCancel={() => setConfirmStatusUpdateModalIsOpen(false)}
              text="Veuillez confirmer le retour de l'article au magasin"
            
            /> */}
              </>
            }

            {
              data.status === "BLOCKED" &&
              <>
                <Button
                  size={SIZE.mini}
                  shape={SHAPE.pill}
                  onClick={() => setConfirmStatusUpdateModalIsOpen(true)}
                >
                  <LongArrowLeft />
                  <span style={{ marginLeft: 6 }}>Retourner au magasin</span>
                </Button>

                <ConfirmUpdateStatusModal
                  isOpen={confirmStatusUpdateModalIsOpen}
                  item={{}}
                  onConfirm={() => {
                    mutateItemStatus({
                      variables: {
                        items: [data.id],
                        action: "RETURN"
                      },
                      // refetchQueries: [{ query: GET_PRODUCTS, variables: { status: "RETRIEVED" } }]
                    })
                    setConfirmStatusUpdateModalIsOpen(false)
                    // closeDrawer();
                  }}
                  onCancel={() => setConfirmStatusUpdateModalIsOpen(false)}
                  text="Veuillez confirmer le retour de l'article au magasin"
                />
              </>
            }
          </div>
        }
      </ProductCardWrapper>
    </>
  );
};

export default ProductCard;
