import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloClient, split, HttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { theme } from './theme';
import Routes from './routes';
import { SnackbarProvider, useSnackbar } from 'notistack'
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import CheckoutProvider from './context/checkout/State';
import PrintProvider from './context/print/State';
import config from './config'
import { styled } from 'baseui';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { register } from './serviceWorker'
register()
// get the authentication token from local storage if it exists
const token = localStorage.getItem('token');

const wsLink = new WebSocketLink({
  uri: config.REACT_APP_API_URL_WS,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: token ? `Bearer ${token}` : "",
    },
  },
});

const httpLink = new createUploadLink({
  uri: config.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export const ButtonClose = styled('div', () => ({
  top: "10px",
  background: "#FFFFFF",
  color: "rgb(102, 109, 146)",
  borderRadius: "50%",
  width: "22px",
  height: "22px",
  textAlign: "center",
  fontWeight: "bold",
  lineHeight: "22px",
  left: "35px",
  fontSize: "14px",
  cursor : 'pointer'
}));

function App() {
  const engine = new Styletron();
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => { 
      // @ts-ignore
      notistackRef.current.closeSnackbar(key);
   
  }

  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            ref={notistackRef}
            action={(key) => (
                <ButtonClose onClick={onClickDismiss(key)}>x</ButtonClose>
            )}
        >
            <CheckoutProvider>
            <PrintProvider>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </PrintProvider>
            </CheckoutProvider>
          </SnackbarProvider>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
