import React, { useState, useEffect, useRef } from 'react';
import { withStyle } from 'baseui';
import { Checkbox } from 'baseui/checkbox';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Formik, Form, Field } from 'formik';
import './style.css'
import {
  Grid,
  Row as Rows,
  Col as Column,
} from '../../components/FlexBox/FlexBox';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { WrapperProfile, Header, Heading } from '../../components/WrapperStyle';
import {
  TableWrapper
} from './Profile.style';
import { FormFields, FormLabel, Error } from '../../components/FormFields/FormFields';
import { names as countries } from '../../utils/countries';
import { honorTitles } from '../../utils/accounts';
import { Avatar } from 'baseui/avatar';
import { Button as BaseButton, SHAPE, KIND } from 'baseui/button';
import { FaPen } from 'react-icons/fa'
import { isTrader, isAdmin } from '../../utils/helpers';
import axios from 'axios';
import CustomSpinner from '../../components/CustomSpinner/CustomSpinner';
import FormikModal from "./FormikModal"
import ChangerPassword from "./modifyPasswordModal"
import { AsyncPaginate } from "react-select-async-paginate";
import { Textarea } from '../../components/Textarea/Textarea';
import Resizer from 'react-image-file-resizer';
import config, { configs } from '../../config';
//@ts-ignore
import S3FileUpload from 'react-s3';
const honorTitleOptions = Object.keys(honorTitles).map(key => ({ label: honorTitles[key], value: key }))
const countryOptions = Object.keys(countries).map(key => ({ label: countries[key], value: key }))
const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));

export const GET_ME = gql`
query {
  me {
			id
			firstName
			lastName
			phoneNumber
			brand
      honorTitle
      country
      city
      nameIsVisible
      roles
      relations {
        id
        honorTitle
        firstName
        lastName
        phoneNumber
      }
      privateRelations {
        firstName
        lastName
        phoneNumber
      }
      address
      postalCode
      photo
      taxNumber
      invoiceAddress
      wordPressSettings{
          hasPrivateWebSite
          consumerKey
          consumerSecret
          version
          url
        }
      smsNotificationSettings  {
        smsNotificationEnabled
        inStoreNotification
        deliveredNotification
        blockedNotification
        paidNotification
        retreivedNotification
    }
    subs {
      subscriptionPeriod
    }
  }
}
`
const UPDATE_USER = gql`
	mutation UpdateUser($data: UserUpdateInput!) {
		updateUser(data: $data) {
			id
      honorTitle
      firstName
      lastName
      phoneNumber
      country
      city
      roles
      photo
      nameIsVisible
      roles
      address
	    postalCode
      taxNumber
      invoiceAddress
      wordPressSettings{
          hasPrivateWebSite
          consumerKey
          consumerSecret
          version
          url
      }
      smsNotificationSettings  {
        smsNotificationEnabled
        inStoreNotification
        deliveredNotification
        blockedNotification
        paidNotification
        retreivedNotification
      }
      subs {
        subscriptionPeriod
      }
		}
	}
`
export const GET_USERS = gql`
  query getUsers($key: String, $first: Int!, $after: String) {
    users(key: $key, first: $first, after: $after) {
      edges{
        node {
          id
          firstName
          lastName
          phoneNumber
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
}
`
export const GET_USER = gql`
  query GetUser($id: ID) {
      user(where: {id: $id}) {
        id
        honorTitle
        firstName
        lastName
        phoneNumber
        country
        city
        nameIsVisible
        photo
        brand
        address
  	    postalCode
        taxNumber
        invoiceAddress
        wordPressSettings{
          hasPrivateWebSite
          consumerKey
          consumerSecret
          version
          url
        }
        smsNotificationSettings  {
          smsNotificationEnabled
          inStoreNotification
          deliveredNotification
          blockedNotification
          paidNotification
          retreivedNotification
        }
        subs {
          subscriptionPeriod
        }
      }
  }
`

export const GET_REGIONS = gql`
query Regions($countryCode:String!,$key:String,$first:Int){
 regions(countryCode:$countryCode,key:$key,first:$first){
   country 
   name
   cityId
 }
}
`
const updateSchema = () => {
  return Yup.object().shape({
    honorTitle: Yup.string().required('Le titre est requis'),
    firstName: Yup.string().required('Le prénom est requis'),
    lastName: Yup.string().required('Le nom est requis'),
    phoneNumber: Yup.string().required('Le numéro de téléphone est requis'),
    country: Yup.string().required('Le nom de pays est requis'),
    city: Yup.string(),
    address: Yup.string(),
    postalCode: Yup.string(),
    taxNumber: Yup.string(),
    invoiceAddress: Yup.string(),
    wordPressSettings: Yup.object(),
    smsNotificationSettings: Yup.object()
  });
};

const MyInput = ({ field, form, ...props }) => {
  return <Input {...field} {...props} />;
};

export default function StuffMembers() {
  const photoRef = useRef()
  const { enqueueSnackbar } = useSnackbar()
  const [options, setOptions] = React.useState([])
  const [regionsOptions, setRegionsOptions] = useState([])
  const [selectedUser, setSelectedUser] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState([]);
  const [usersFirst, setUsersFirst] = useState(10)
  const [usersKey, setUsersKey] = useState(null)
  const [usersAfter, setUsersAfter] = useState("")
  const [check, setCheck] = useState(false)
  const [smscheck, setSmsCheck] = useState(false)
  const [invoiceAddress, setInvoiceAddress] = useState('');
  const [disabled, setDisabled] = useState(false)
  const [regionsLoad, setRegionsLoad] = useState([0])

  const [key, setKey] = useState("")
  const [first, setFirst] = useState(20)
  const [city, Onchange] = React.useState(null);
  const [selectedcountryCode, setSelectedCountryCode] = useState('')
  const [regionsLoadIndex, setRegionsLoadIndex] = useState([0])
  const [selectedRegions, SetSelectedRegions] = useState([])
  const { data } = useQuery(GET_ME, { fetchPolicy: "network-only" })
  const [traderFilter, { data: dataUsers }] = useLazyQuery(GET_USERS)
  const { data: dataUser } = useQuery(GET_USER, {
    variables: { id: selectedId },
  });


  const [filterRegion, { data: regionsData }] = useLazyQuery(GET_REGIONS, {
    fetchPolicy: "network-only",
    onError(error) {
      console.log({ error })
    },
    onCompleted({ region }) {

      console.log("Fetch completed for", selectedcountryCode);
      console.log(JSON.stringify(region, null, 2));
    }
  })
  const [initialValues, setInitialValues] = useState({
    id: '',
    honorTitle: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: '',
    nameIsVisible: false,
    photo: '',
    brand: '',
    address: '',
    postalCode: '',
    taxNumber: '',
    hasPrivateWebSite: false,
    consumerKey: "",
    consumerSecret: "",
    version: "",
    url: "",
    smsNotificationEnabled: false,
    inStoreNotification: false,
    deliveredNotification: false,
    blockedNotification: false,
    paidNotification: false,
    retreivedNotification: false,
    subscriptionPeriod: "",
  })

  useEffect(() => {
    setInitialValues({
      id: data && data.me && data.me.id ? data.me.id : '',
      honorTitle: data && data.me && data.me.honorTitle ? data.me.honorTitle : '',
      firstName: data && data.me && data.me.firstName ? data.me.firstName : '',
      lastName: data && data.me && data.me.lastName ? data.me.lastName : '',
      phoneNumber: data && data.me && data.me.phoneNumber ? data.me.phoneNumber : '',
      country: data && data.me && data.me.country ? data.me.country : '',
      nameIsVisible: data && data.me && data.me.nameIsVisible ? data.me.nameIsVisible : false,
      photo: data && data.me && data.me.photo ? data.me.photo : '',
      brand: data && data.me && data.me.brand ? data.me.brand : '',
      address: data && data.me && data.me.address ? data.me.address : '',
      postalCode: data && data.me && data.me.postalCode ? data.me.postalCode : '',
      taxNumber: data && data.me && data.me.taxNumber ? data.me.taxNumber : '',
      hasPrivateWebSite: data && data.me && data.me.wordPressSettings.hasPrivateWebSite ? data.me.wordPressSettings.hasPrivateWebSite : false,
      consumerKey: data && data.me && data.me.wordPressSettings.consumerKey ? data.me.wordPressSettings.consumerKey : '',
      consumerSecret: data && data.me && data.me.wordPressSettings.consumerSecret ? data.me.wordPressSettings.consumerSecret : '',
      version: data && data.me && data.me.wordPressSettings.version ? data.me.wordPressSettings.version : '',
      url: data && data.me && data.me.wordPressSettings ? data.me.wordPressSettings.url : '',
      smsNotificationEnabled: data && data.me && data.me.smsNotificationSettings ? data.me.smsNotificationSettings.smsNotificationEnabled : false,
      deliveredNotification: data && data.me && data.me.smsNotificationSettings ? data.me.smsNotificationSettings.deliveredNotification : false,
      blockedNotification: data && data.me && data.me.smsNotificationSettings ? data.me.smsNotificationSettings.blockedNotification : false,
      paidNotification: data && data.me && data.me.smsNotificationSettings ? data.me.smsNotificationSettings.paidNotification : false,
      retreivedNotification: data && data.me && data.me.smsNotificationSettings ? data.me.smsNotificationSettings.retreivedNotification : false,
      inStoreNotification: data && data.me && data.me.smsNotificationSettings ? data.me.smsNotificationSettings.inStoreNotification : false,
      subscriptionPeriod: data && data.me && data.me.subs && data.me.subs.subscriptionPeriod,
    })
    setSelectedId(data?.me?.id)
    setSelectedCountryCode(data?.me.country)
    Onchange(data && data.me && data.me.city ? { value: data.me.city, label: data.me.city } : '')
    setInvoiceAddress(data && data.me && data.me.invoiceAddress ? data.me.invoiceAddress : '')
  }, [data])

  const [updateMe, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    onError(error) {
      console.log({ error })
      let errorMessage = (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message) || 'Failed to update'
      errorMessage = errorMessage.split(':')[0]
      enqueueSnackbar(errorMessage, { variant: "error" })
    },
    update(_, { data }) {
      enqueueSnackbar('Profil modifié avec succès', { variant: "success" })
    },
    refetchQueries: [
      { query: GET_ME }
    ]
  })

  useEffect(() => {
    let tab = []
    dataUsers?.users.edges.forEach(element => {
      tab.push({ value: element.node.id, label: `${element.node.firstName} ${element.node.lastName} ${element.node.phoneNumber}` })
    });
    setOptions(tab)
  }, [dataUsers, usersFirst, usersKey])

  //loadOptions for the select user to change (Admin only)
  let maxf = 0;
  let maxs = 0;

  const loadOptions = async (search, prevOptions) => {
    let filteredOptions;
    let searchedOptions;

    // if the user dont write anything in the search input
    if (!search) {
      setUsersFirst(maxf)
      setUsersKey(" ")
      filteredOptions = options;
      if (prevOptions.length >= maxf) {
        maxf = prevOptions.length
        maxf += 10;
      }
      const hasMore = dataUsers?.users.pageInfo.hasNextPage;
      const slicedOptions = filteredOptions.slice(
        maxf - 10,
        maxf
      );
      return {
        options: slicedOptions,
        hasMore: hasMore
      };
      // if the user want to search by name or by phone number
    } else {
      const searchLower = search.toLowerCase();
      setUsersFirst(maxs)
      setUsersKey(searchLower)
      searchedOptions = options
      const hasMores = dataUsers?.users.pageInfo.hasNextPage;
      if (prevOptions.length >= maxs) {
        maxs = prevOptions.length
        maxs += 10;
      }
      const slicedOptions = searchedOptions.slice(
        maxs - 10,
        maxs
      );

      return {
        options: slicedOptions,
        hasMore: hasMores
      };
    }
  };
  useEffect(() => {
    filterRegion
      ({
        variables: {
          countryCode: selectedcountryCode,
          key: key,
          first: first
        }
      })
  }, [selectedcountryCode, key])

  useEffect(() => {
    if (regionsData && regionsData.regions) {
      setRegionsOptions(regionsData.regions.map(element => {
        return { value: element.name, label: element.name }
      }))
    }
  }, [regionsData])

  const load = async (search, prevOptions) => {

    let filteredOptions;
    if (!search) {
      filteredOptions = regionsOptions;
      setKey("")
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = regionsOptions.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore
    };
  };

  useEffect(() => {
    Onchange("")
  }, [selectedcountryCode])

  useEffect(() => {
    traderFilter({
      variables: {
        first: usersFirst,
        key: usersKey,
        after: usersAfter
      },
    });
  }, [usersFirst, usersKey,]);

  useEffect(() => {
    setInitialValues({
      id: dataUser && dataUser.user && dataUser.user.id ? dataUser.user.id : '',
      honorTitle: dataUser && dataUser.user && dataUser.user.honorTitle ? dataUser.user.honorTitle : '',
      phoneNumber: dataUser && dataUser.user && dataUser.user.phoneNumber ? dataUser.user.phoneNumber : '',
      firstName: dataUser && dataUser.user && dataUser.user.firstName ? dataUser.user.firstName : '',
      lastName: dataUser && dataUser.user && dataUser.user.lastName ? dataUser.user.lastName : '',
      country: dataUser && dataUser.user && dataUser.user.country ? dataUser.user.country : '',
      nameIsVisible: dataUser && dataUser.user && dataUser.user.nameIsVisible ? dataUser.user.nameIsVisible : false,
      photo: dataUser && dataUser.user && dataUser.user.photo ? dataUser.user.photo : '',
      brand: dataUser && dataUser.user && dataUser.user.brand ? dataUser.user.brand : '',
      address: dataUser && dataUser.user && dataUser.user.address ? dataUser.user.address : '',
      postalCode: dataUser && dataUser.user && dataUser.user.postalCode ? dataUser.user.postalCode : '',
      taxNumber: dataUser && dataUser.user && dataUser.user.taxNumber ? dataUser.user.taxNumber : '',
      hasPrivateWebSite: dataUser && dataUser.user && dataUser.user.wordPressSettings ? dataUser.user.wordPressSettings.hasPrivateWebSite : false,
      consumerKey: dataUser && dataUser.user && dataUser.user.wordPressSettings ? dataUser.user.wordPressSettings.consumerKey : '',
      consumerSecret: dataUser && dataUser.user && dataUser.user.wordPressSettings ? dataUser.user.wordPressSettings.consumerSecret : '',
      version: dataUser && dataUser.user && dataUser.user.wordPressSettings ? dataUser.user.wordPressSettings.version : '',
      url: dataUser && dataUser.user && dataUser.user.wordPressSettings ? dataUser.user.wordPressSettings.url : '',
      smsNotificationEnabled: dataUser && dataUser.user && dataUser.user.smsNotificationSettings ? dataUser.user.smsNotificationSettings.smsNotificationEnabled : false,
      deliveredNotification: dataUser && dataUser.user && dataUser.user.smsNotificationSettings ? dataUser.user.smsNotificationSettings.deliveredNotification : false,
      blockedNotification: dataUser && dataUser.user && dataUser.user.smsNotificationSettings ? dataUser.user.smsNotificationSettings.blockedNotification : false,
      paidNotification: dataUser && dataUser.user && dataUser.user.smsNotificationSettings ? dataUser.user.smsNotificationSettings.paidNotification : false,
      retreivedNotification: dataUser && dataUser.user && dataUser.user.smsNotificationSettings ? dataUser.user.smsNotificationSettings.retreivedNotification : false,
      inStoreNotification: dataUser && dataUser.user && dataUser.user.smsNotificationSettings ? dataUser.user.smsNotificationSettings.inStoreNotification : false,
      subscriptionPeriod: dataUser && dataUser.user && dataUser.user.subs && dataUser.user.subs.subscriptionPeriod,

    })
    Onchange(dataUser && dataUser.user && dataUser.user.city ? { value: dataUser.user.city, label: dataUser.user.city } : 'hghghhg')
    setSelectedCountryCode(dataUser && dataUser.user && dataUser.user.country ? dataUser.user.country : '')
    setInvoiceAddress(dataUser && dataUser.user && dataUser.user.invoiceAddress ? dataUser.user.invoiceAddress : '');


  }, [dataUser])
  const [imageLoading, setImageLoading] = useState(false)

  // select the user who we want to change his informations
  const selectUserToChange = (value) => {
    setSelectedUser(value)
    setSelectedId(value?.value)
    setCheck(false);
  }
  const onInputChange = (newInputValue, { action }) => {
    setKey(newInputValue)
  };

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 640, 640, 'JPEG', 90, 0,
      uri => {
        resolve(uri);
      },
      'blob'
    );
  });

  return (
    <Grid fluid={true}>

      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 40,
              boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
            }}
          >
            <Col md={3} xs={12}>
              <Heading>Paramètres</Heading>
            </Col>
            <Col md={9} xs={12}>
              {isAdmin() &&
                <FormFields>
                  <AsyncPaginate
                    id="asyncPaginate"
                    onMenuOpen={() => setRegionsLoad([regionsLoad[0] + 1])}
                    cacheUniqs={regionsLoad}
                    debounceTimeout={300}
                    value={selectedUser}
                    loadOptions={loadOptions}
                    onChange={(value) => selectUserToChange(value)}
                    isSearchable={true}
                    placeholder="Selectionner..."
                  />
                </FormFields>
              }
            </Col>
          </Header>
          <WrapperProfile style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)', padding: 20 }}>
            <TableWrapper >
              <Formik
                initialValues={initialValues}
                onSubmit={values => updateMe({
                  variables: {
                    data: {
                      ...values,
                      city: city.value,
                      invoiceAddress,
                      hasPrivateWebSite: undefined,
                      consumerKey: undefined,
                      consumerSecret: undefined,
                      version: undefined,
                      url: undefined,
                      smsNotificationEnabled: undefined,
                      inStoreNotification: undefined,
                      deliveredNotification: undefined,
                      blockedNotification: undefined,
                      paidNotification: undefined,
                      retreivedNotification: undefined,
                      subscriptionPeriod: undefined,
                      wordPressSettings: {
                        hasPrivateWebSite: values.hasPrivateWebSite,
                        consumerKey: values.consumerKey,
                        consumerSecret: values.consumerSecret,
                        version: values.version,
                      },
                      smsNotificationSettings: {
                        smsNotificationEnabled: values.smsNotificationEnabled,
                        inStoreNotification: values.inStoreNotification,
                        deliveredNotification: values.deliveredNotification,
                        blockedNotification: values.blockedNotification,
                        paidNotification: values.paidNotification,
                        retreivedNotification: values.retreivedNotification
                      },
                      subs: {
                        subscriptionPeriod: parseInt(values.subscriptionPeriod)
                      }
                    }
                  }
                })}
                validationSchema={updateSchema}
                enableReinitialize
              >
                {({ errors, status, touched, values, setFieldValue, }) => {
                  const fullName = `${values && values.firstName ? values.firstName : ''} ${values && values.lastName ? values.lastName : ''}`
                  if (check) {
                    setInvoiceAddress(`${values.address}, ${values.postalCode}, ${city.value}, ${values.country}`)
                  }

                  return (
                    <Form style={{ height: "100%" }}>
                      <Row >
                        <Col md={3}>
                          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Avatar
                              name={values.brand ? values.brand : fullName}
                              size={"100px"}
                              src={values.photo}
                            />
                            <BaseButton
                              type="button"
                              shape={SHAPE.round}
                              kind={KIND.secondary}
                              // @ts-ignore
                              onClick={() => photoRef.current.click()}
                              overrides={{
                                Root: {
                                  style: {
                                    marginLeft: '-20px'
                                  }
                                }
                              }}
                            >
                              <FaPen />
                            </BaseButton>
                            {
                              imageLoading && <CustomSpinner />
                            }
                            <input
                              type="file"
                              placeholder="Photo"
                              style={{ display: "none" }}
                              ref={photoRef}
                              onChange={async (e) => {
                                const file = e.target.files[0]
                                const image = await resizeFile(file);
                                //@ts-ignore
                                let fileToUpload = new File([image], `${Date.now()}${file.name}`)
                                if (file.size < fileToUpload.size) {
                                  fileToUpload = new File([file], `${Date.now()}${file.name}`);
                                }
                                setImageLoading(true)
                                if (config.CLOUD == "aws") {
                                  S3FileUpload
                                    .uploadFile(fileToUpload, configs)
                                    .then(data => {
                                      setFieldValue("photo", data.location)
                                    })
                                    .then(() => setImageLoading(false))
                                    .catch(err => console.error(err))
                                } else if (config.CLOUD == "cloudinary") {
                                  const formData = new FormData();
                                  formData.append("upload_preset", "depovente")
                                  // @ts-ignore
                                  formData.append("file", image)
                                  axios.post("https://api.cloudinary.com/v1_1/zribicom/image/upload", formData)
                                    .then(res => setFieldValue("photo", res.data.secure_url))
                                    .then(() => setImageLoading(false))
                                    .catch(err => console.log({ err }))
                                }
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={9}>

                          <Row>
                            {isTrader() && (
                              <Col md={4}>
                                <FormFields>
                                  <FormLabel>Nom de la boutique</FormLabel>
                                  <Field
                                    type="text"
                                    name="brand"
                                    component={MyInput}
                                  />
                                  {errors.brand && touched.brand && (
                                    <Error>{errors.brand}</Error>
                                  )}
                                </FormFields>
                              </Col>
                            )
                            }
                            {isAdmin() ?
                              <Col md={4}><FormLabel>Numéro de téléphone</FormLabel><Field type="text" name="phoneNumber" component={MyInput} /></Col> : <Col md={4}> <FormikModal /> </Col>
                            }
                            {data?.getMe?.id === selectedId && <Col md={4}>
                              <ChangerPassword />
                            </Col>}
                          </Row>

                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <FormFields>
                            <FormLabel>Titre</FormLabel>
                            <Select
                              options={honorTitleOptions}
                              labelKey="label"
                              valueKey="value"
                              placeholder={false}
                              value={honorTitleOptions.filter(item => item.value === values.honorTitle)}
                              searchable={true}
                              onChange={({ value }) => {
                                setFieldValue('honorTitle', value && value[0] && value[0].value ? value[0].value : '');
                              }}
                            />
                            {errors.honorTitle && touched.honorTitle && (
                              <Error>{errors.honorTitle}</Error>
                            )}
                          </FormFields>
                        </Col>
                        <Col md={5}>
                          <FormFields>
                            <FormLabel>Prénom</FormLabel>
                            <Field
                              type="text"
                              name="firstName"
                              component={MyInput}
                            />
                            {errors.firstName && touched.firstName && (
                              <Error>{errors.firstName}</Error>
                            )}
                          </FormFields>
                        </Col>
                        <Col md={5}>
                          <FormFields>
                            <FormLabel>Nom</FormLabel>
                            <Field
                              type="text"
                              name="lastName"
                              component={MyInput}
                            />
                            {errors.lastName && touched.lastName && (
                              <Error>{errors.lastName}</Error>
                            )}
                          </FormFields>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormFields>
                            <FormLabel>Adresse</FormLabel>
                            <Field
                              type="text"
                              name="address"
                              component={MyInput}
                              placeholder="Quelle est votre adresse?"
                            />
                            {errors.address && touched.address && (
                              <Error>{errors.address}</Error>
                            )}
                          </FormFields>
                        </Col>
                        <Col md={6}>
                          <FormFields>
                            <FormLabel>Pays</FormLabel>
                            < Select
                              options={countryOptions}
                              labelKey="label"
                              valueKey="value"
                              placeholder={false}
                              value={countryOptions.filter(item => item.value === values.country)}
                              searchable={true}
                              onChange={({ value }) => {
                                setSelectedCountryCode(value && value[0] && value[0].value ? value[0].value : '');
                                setFieldValue('country', value && value[0] && value[0].value ? value[0].value : '');
                              }}
                            />
                            {errors.country && touched.country && (
                              <Error>{errors.country}</Error>
                            )}
                          </FormFields>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormFields>
                            <FormLabel>Code Postal</FormLabel>
                            <Field
                              type="text"
                              name="postalCode"
                              component={MyInput}
                              placeholder="Code postal"
                            />
                            {errors.postalCode && touched.postalCode && (
                              <Error>{errors.postalCode}</Error>
                            )}
                          </FormFields>
                        </Col>
                        <Col md={6}>
                          <FormFields>
                            <FormLabel>Ville</FormLabel>
                            < AsyncPaginate
                              onMenuOpen={() => setRegionsLoadIndex([regionsLoadIndex[0] + 1])}
                              loadOptions={load}
                              onInputChange={onInputChange}
                              isSearchable={true}
                              cacheUniqs={regionsLoadIndex}
                              placeholder="Choisir votre ville "
                              onChange={(value) => Onchange(value)}
                              value={city}
                              debounceTimeout={500}
                            />
                            {errors.country && touched.country && (
                              <Error>{"Le nom de la ville est requis"}</Error>
                            )}
                          </FormFields>
                        </Col>
                      </Row>
                      <Row>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Checkbox
                            checked={check}
                            children={<FormLabel>Utiliser la même adresse pour la facturation</FormLabel>}
                            onChange={() => {
                              setCheck(!check)
                              if (!check) {
                                setInvoiceAddress(`${values.address}, ${values.postalCode}, ${city.value} , ${values.country}`)
                              }
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={9}>
                          <FormFields >
                            <FormLabel>Adresse de facturation</FormLabel>
                            <Textarea
                              value={invoiceAddress}
                              onChange={e => setInvoiceAddress(e.target.value)}
                              disabled={check ? true : false}
                            />
                          </FormFields>
                        </Col>
                        <Col md={3}>
                          <FormFields>
                            <FormLabel>Numéro TVA</FormLabel>
                            <Field
                              type="text"
                              name="taxNumber"
                              component={MyInput}
                              placeholder=" Matricule fiscale..."
                            />
                            {errors.address && touched.address && (
                              <Error>{errors.address}</Error>
                            )}
                          </FormFields>
                        </Col>
                      </Row>
                      <Row>
                        <Checkbox
                          checked={values.nameIsVisible}
                          children={<FormLabel>Afficher mon nom complet</FormLabel>}
                          onChange={() => setFieldValue('nameIsVisible', !values.nameIsVisible)}
                        />
                      </Row>
                      <Button
                        type="submit"
                        disabled={updateLoading}
                        isLoading={updateLoading}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              width: '100%',
                              marginLeft: 'auto',
                              borderTopLeftRadius: '3px',
                              borderTopRightRadius: '3px',
                              borderBottomLeftRadius: '3px',
                              borderBottomRightRadius: '3px',
                              '@media only screen and (max-width: 767px)': {
                                marginBottom: '10px'
                              },
                            }),
                          },
                        }}
                      >
                        Enregistrer
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </TableWrapper>
          </WrapperProfile>
          {isAdmin() &&
            <WrapperProfile style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)', padding: 20, marginTop: "5%", height: "65%" }}>
              <TableWrapper >
                <Formik
                  initialValues={initialValues}

                  onSubmit={values => updateMe({
                    variables: {
                      data: {
                        ...values,
                        city: city.value,
                        invoiceAddress,
                        hasPrivateWebSite: undefined,
                        consumerKey: undefined,
                        consumerSecret: undefined,
                        version: undefined,
                        url: undefined,
                        smsNotificationEnabled: undefined,
                        inStoreNotification: undefined,
                        deliveredNotification: undefined,
                        blockedNotification: undefined,
                        paidNotification: undefined,
                        retreivedNotification: undefined,
                        subscriptionPeriod: undefined,
                        wordPressSettings: {
                          hasPrivateWebSite: values.hasPrivateWebSite,
                          consumerKey: values.consumerKey,
                          consumerSecret: values.consumerSecret,
                          version: values.version,
                          url: values.url,
                        },
                        smsNotificationSettings: {
                          smsNotificationEnabled: values.smsNotificationEnabled,
                          inStoreNotification: values.inStoreNotification,
                          deliveredNotification: values.deliveredNotification,
                          blockedNotification: values.blockedNotification,
                          paidNotification: values.paidNotification,
                          retreivedNotification: values.retreivedNotification
                        },
                        subs: {
                          subscriptionPeriod: parseInt(values.subscriptionPeriod)
                        }
                      }
                    }
                  })
                  }
                  validationSchema={updateSchema}
                  enableReinitialize
                >
                  {({ errors, status, touched, values, setFieldValue }) => {
                    const fullName = `${values && values.firstName ? values.firstName : ''} ${values && values.lastName ? values.lastName : ''}`
                    return (
                      <Form style={{ height: "100%" }}>
                        <Row>
                          <Col md={6}>
                            <FormFields>
                              <FormLabel>Durée d'abonnement</FormLabel>
                              <Field
                                type="text"
                                name="subscriptionPeriod"
                                component={MyInput}
                                placeholder="Periode de facturation"
                              />
                              {errors.subscriptionPeriod && touched.subscriptionPeriod && (
                                <Error>{errors.subscriptionPeriod}</Error>
                              )}
                            </FormFields>
                          </Col>
                          <Col md={6}>
                            <FormFields>
                              <Checkbox
                                checked={values.hasPrivateWebSite}
                                children={<FormLabel>Activer le site Wordpress du client</FormLabel>}
                                onChange={() => setFieldValue("hasPrivateWebSite", !values.hasPrivateWebSite)}
                              />
                            </FormFields>
                          </Col>
                          <Col md={6}>
                            <FormFields>
                              <FormLabel>Clé de client</FormLabel>
                              <Field
                                type="text"
                                name="consumerKey"
                                component={MyInput}
                                placeholder="Clé client WooCommerce"
                              />
                              {errors.consumerKey && touched.consumerKey && (
                                <Error>{errors.consumerKey}</Error>
                              )}
                            </FormFields>
                          </Col>
                          <Col md={6}>
                            <FormFields>
                              <FormLabel>Clé secret de client</FormLabel>
                              <Field
                                type="text"
                                name="consumerSecret"
                                component={MyInput}
                                placeholder="Clé secréte WooCommerce"
                              />
                              {errors.consumerSecret && touched.consumerSecret && (
                                <Error>{errors.consumerSecret}</Error>
                              )}
                            </FormFields>
                          </Col>
                          <Col md={6}>
                            <FormFields>
                              <FormLabel>Version</FormLabel>
                              <Field
                                type="text"
                                name="version"
                                component={MyInput}
                                placeholder="Version API WooCommerce (wc/v3)"
                              />
                              {errors.version && touched.version && (
                                <Error>{errors.version}</Error>
                              )}
                            </FormFields>
                          </Col>
                          <Col md={6}>
                            <FormFields>
                              <FormLabel>Url</FormLabel>
                              <Field
                                type="text"
                                name="url"
                                component={MyInput}
                                placeholder="URL du site web"
                              />
                              {errors.url && touched.url && (
                                <Error>{errors.url}</Error>
                              )}
                            </FormFields>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Checkbox
                              checked={values.smsNotificationEnabled}
                              children={<FormLabel>Notification par SMS</FormLabel>}
                              onChange={() => setFieldValue("smsNotificationEnabled", !values.smsNotificationEnabled)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            <Checkbox
                              checked={values.paidNotification}
                              children={<FormLabel>SMS Article vendu</FormLabel>}
                              onChange={() => setFieldValue("paidNotification", !values.paidNotification)}
                            />
                          </Col>
                          <Col md={2}>
                            <Checkbox
                              checked={values.blockedNotification}
                              children={<FormLabel>SMS Article bloqué</FormLabel>}
                              onChange={() => setFieldValue("blockedNotification", !values.blockedNotification)}
                            />
                          </Col>
                          <Col md={2}>
                            <Checkbox
                              checked={values.deliveredNotification}
                              children={<FormLabel>SMS Article réservé</FormLabel>}
                              onChange={() => setFieldValue("deliveredNotification", !values.deliveredNotification)}
                            />

                          </Col>
                          <Col md={2}>
                            <Checkbox
                              checked={values.retreivedNotification}
                              children={<FormLabel>SMS Article récupéré</FormLabel>}
                              onChange={() => setFieldValue("retreivedNotification", !values.retreivedNotification)}
                            />

                          </Col><Col md={2}>
                            <Checkbox
                              checked={values.inStoreNotification}
                              children={<FormLabel>SMS Article ajouté</FormLabel>}
                              onChange={() => setFieldValue("inStoreNotification", !values.inStoreNotification)}
                            />
                          </Col>
                        </Row>
                        <Button
                          type="submit"
                          disabled={updateLoading}
                          isLoading={updateLoading}
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                width: '100%',
                                marginLeft: 'auto',
                                borderTopLeftRadius: '3px',
                                borderTopRightRadius: '3px',
                                borderBottomLeftRadius: '3px',
                                borderBottomRightRadius: '3px',
                                '@media only screen and (max-width: 767px)': {
                                  marginBottom: '10px'
                                },
                              }),
                            },
                          }}
                        >
                          Enregistrer
                      </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </TableWrapper>
            </WrapperProfile>
          }
        </Col>
      </Row>
    </Grid>
  );
}