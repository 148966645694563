import React, { useState, useEffect, useRef } from 'react';
import { withStyle } from 'baseui';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import { Formik, Form, Field } from 'formik';

import {
    Grid,
    Row as Rows,
    Col as Column,
} from '../../components/FlexBox/FlexBox';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { FormFields, FormLabel, Error } from '../../components/FormFields/FormFields';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';
import { useHistory } from 'react-router-dom';

const Col = withStyle(Column, () => ({
    '@media only screen and (max-width: 767px)': {
        marginBottom: '20px',

        ':last-child': {
            marginBottom: 0,
        },
    },
}));

const Row = withStyle(Rows, () => ({
    '@media only screen and (min-width: 768px)': {
        alignItems: 'center',
    },
}));
const MyInput = ({ field, form, ...props }) => {
    return <Input {...field} {...props} />;
};


const MODIFY_PASSWORD = gql`
mutation ModifyPassword($oldPassword:String!,$newPassword:String!,$confirmationPassword:String!){
  modifyPassword(oldPassword:$oldPassword,newPassword:$newPassword,confirmationPassword:$confirmationPassword)
}
`

export default function ChangePhoneNumberModal() {
    const [isOpen, setIsOpen] = React.useState(false);
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    
    function close() {
        setIsOpen(false);
    }

    const [modifyPassword, { loading }] = useMutation(MODIFY_PASSWORD, {
        onError(error) {
            console.log({ error })
            let errorMessage = (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message) || 'Failed to update'
            errorMessage = errorMessage.split(':')[0]
            enqueueSnackbar(errorMessage, { variant: "error" })
           
        },
        update(_, { data }) {
            if(data.modifyPassword){
               
            enqueueSnackbar('Votre mot de passe a été bien changé ', { variant: "success" })
            setIsOpen(false)
            
        }},
    })



    let change = ({oldPassword, newPassword,confirmationPassword }, { setSubmitting }) => {
    
    modifyPassword({
            variables: {
                oldPassword,
                newPassword,
                confirmationPassword
            }
        })
        
        //  history.push("/")
    };


    return (
        <>
            <Button style={{ marginTop: "30px",width:"100%" }} type="button" onClick={() => setIsOpen(true)}>Changer mot de passe</Button>
            <Modal onClose={close} isOpen={isOpen}>
                <Formik
                    initialValues={{ oldPassword: "", newPassword: "",confirmationPassword:"" }}
                    onSubmit={change}
                    
                    enableReinitialize
                >
                    {({ values, errors, isSubmitting, isValid }) => {
                        console.log({ values, errors, isSubmitting, isValid });
                        return (
                            <Form>
                                <React.Fragment>
                                    <ModalBody>
                                        <FormFields>
                                            <FormLabel>Entrer votre ancien mot de passe</FormLabel>
                                            <Field
                                                type="password"
                                                name="oldPassword"
                                                component={MyInput}
                                            />
                                        </FormFields>
                                        <FormFields>
                                            <FormLabel>Entrer votre nouveau mot de passe</FormLabel>
                                            <Field
                                                type="password"
                                                name="newPassword"
                                                component={MyInput}
                                            />
                                        </FormFields>
                                        <FormFields>
                                            <FormLabel>Confirmer votre nouveau mot de passe</FormLabel>
                                            <Field
                                                type="password"
                                                name="confirmationPassword"
                                                component={MyInput}
                                            />
                                        </FormFields>
                                        
                                    </ModalBody>
                                    <ModalFooter>
                                        
                                        
                                        <ModalButton kind="tertiary" onClick={close} type="button">
                                            Annuler
    </ModalButton>
                                        <ModalButton>Changer</ModalButton>
                                    </ModalFooter>

                                </React.Fragment>
                            </Form>
                        )
                    }}

                </Formik>
            </Modal>
        </>
    )





}