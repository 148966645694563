// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
// export const PRODUCTS = '/products';
export const PRODUCTS = '/';
export const CATEGORY = '/category';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const CUSTOMERS = '/customers';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const STUFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const SIGNIN = '/signin';
export const SIGNIN_WITH_PASSWORD = '/signin-with-password';
export const SIGNUP = '/signup';
export const PROFILE = '/profile';
export const INVOICES = '/invoices';
export const VERIFY_PHONE_NUMBER = '/verify';
export const VERIFY_AND_SET_PASSWORD = '/verify-and-set-password';
export const CHECKOUT = '/checkout';
export const RECEIPT = '/receipt';
export const HISTORY = '/history';
export const REGULATIONS = '/regulations';
export const BENEFITS = '/benefits';
export const RETURN = '/items-returns';
export const UPDATE_PASSWORD='/verifyPassword'
export const CONFIRM_UPDATE_PASSWORD='/confirmNewPassword'
export const SHOP_URL = "http://localhost:3000"
export const RELATIONS = '/relations'
export const CHANGE_PHONE_NUMBER='/verifyNewPhoneNumber'
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';

