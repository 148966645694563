import * as React from 'react';
import { Button } from 'baseui/button';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';
import { FormLabel } from '../FormFields/FormFields';
import { ProductImageWrapper, Image } from '../ProductCard/ProductCard.style';
import QRCode from 'qrcode.react'
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {BlockProps} from 'baseui/block';

const itemProps: BlockProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const ItemDetailsModal = ({ isOpen, item, onCancel }) => {
    return (
        <React.Fragment>
            <Modal
                onClose={onCancel}
                isOpen={isOpen}
            >
                <ModalHeader>Détails</ModalHeader>
                <ModalBody>
                    {/* {text} */}

                    <ProductImageWrapper>
                        <Image url={item.image} className="product-image" />
                    </ProductImageWrapper>
                    <FlexGrid flexGridColumnCount={2} >
                        <FlexGridItem>
                            <div>
                                <FormLabel>Article: </FormLabel>
                                {item.name}
                            </div>

                            <div>
                                <FormLabel>Description: </FormLabel>
                                {item.description}
                            </div>

                            <div>
                                <FormLabel>Montant: </FormLabel>
                                {(item.salePrice * (1 - item?.agreement?.traderPercentage / 100))?.toFixed(3)} TND
                            </div>

                            <div>
                                <FormLabel>Dépôt-vente: </FormLabel>
                            </div>
                            <div style={{marginLeft: 40}}>
                                <div>
                                {item?.trader?.brand && `${item.trader.brand}`}
                                </div>
                                <div>
                                {item.trader && `${item?.trader?.firstName ? item.trader.firstName : ''} ${item?.trader?.lastName ? item.trader.lastName : ''}`}
                                </div>
                                <div>
                                {item.trader && `${item?.trader?.phoneNumber ? item.trader.phoneNumber : ''}`}
                                </div>
                            </div>

                        </FlexGridItem>
                        <FlexGridItem {...itemProps}>
                            <QRCode value={item.ref} size="96"/>
                        </FlexGridItem>
                    </FlexGrid>

                </ModalBody>
                <ModalFooter>
                    <ModalButton kind="tertiary" onClick={onCancel}>
                        Fermer
                    </ModalButton>
                    {/* <ModalButton onClick={onConfirm}>OK</ModalButton> */}
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default ItemDetailsModal