import * as React from "react";
import QRCode from 'qrcode.react'
import { FormLabel } from "../FormFields/FormFields";

export const Ticket = (props) => {
    return (
    <div className="flexGrids"
        style={{
            position: 'absolute',
            top: `${props?.positions[props.index]?.top ? props.positions[props.index].top : 0}`,
            left : `${props?.positions[props.index]?.left  ? props.positions[props.index].left : 0}`,
            width: '5.25cm  !important',
            height: '2.97cm  !important',
            padding:' 0 !important',
            zIndex: 6,
        }}
    >
      <div className="flex-grid-item">
        <QRCode value={props.data.ref} size="58"/>
      </div>
      <div id="flex-grid-item-details" className="flex-grid-item">
        <div>
          <FormLabel>Réf : </FormLabel>
          {props.data.ref}
        </div>
        {props.name && (
          <div>
            <FormLabel>Déposant : </FormLabel>
            {props.name
              .replace(props.data.provider.phoneNumber, "")
              .split(" - ")
              .join("")
              .split(" ")
              .reduce((accumulator, cV) => accumulator + cV[0], "")}
          </div>
        )}
        {props.data.discountInPercent && props.data.discountInPercent > 0 ? (
          <div>
            <div>
              <span style={{ textDecoration: "line-through" }}>
                {props.data.price} TND
              </span>
            </div>
            <div>
              <FormLabel>-</FormLabel>
              {props.data.discountInPercent}%
            </div>
            <div
              style={{ transform: "scale(1.6)", transformOrigin: "top left" }}
            >
              {props.data.salePrice} TND
            </div>
          </div>
        ) : (
          <div style={{ transform: "scale(1.6)", transformOrigin: "top left" }}>
            {" "}
            {props.data.salePrice} TND
          </div>
        )}
      </div>
    </div>
  );
};
