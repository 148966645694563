import * as React from 'react';
import {
  PhoneInput,
  COUNTRIES,
  CountrySelectDropdown,
  StyledFlag,
} from 'baseui/phone-input';
const {useEffect} = React;

function CustomFlag(props: any) {
  const {children, ...rest} = props;
  return <StyledFlag iso={props.$iso} {...rest} />;
}

interface Props {
  onPhoneNumberChange?: (number: string) => void,
  overrides?: any,
  initialValue?: string
}

const PhoneNumberInput: React.FC<Props> = ({onPhoneNumberChange, overrides, initialValue}) => {
  let textInitialValue = localStorage.getItem("adminRememberMePhoneNumber") || ""
  if (initialValue || initialValue === "") {
    textInitialValue = initialValue
  }
  const [text, setText] = React.useState(textInitialValue);
  const [country, setCountry] = React.useState(COUNTRIES[localStorage.getItem("adminRememberMeCountryId")] || COUNTRIES.TN);
  useEffect(() => {
    onPhoneNumberChange && onPhoneNumberChange(`${country.dialCode}${text}`)
  }, [text, country])
  useEffect(() => {
    setText(initialValue)
  }, [initialValue])
  return (
    <PhoneInput
      text={text}
      country={country}
      onTextChange={event => {
        setText(event.currentTarget.value);
        localStorage.setItem("adminRememberMePhoneNumber", event.currentTarget.value);
      }}
      onCountryChange={(event: any) => {
        setCountry(event.option);
        console.log(event.option);
        localStorage.setItem("adminRememberMeCountryId", event.option.id)
      }}
      overrides={{
        FlagContainer: {
          component: CustomFlag,
        },
        CountrySelect: {
          props: {
            overrides: {
              ...overrides,
              Dropdown: {
                component: CountrySelectDropdown,
              },
            },
          },
        },
      }}
    />
  );
};

export default PhoneNumberInput;