import React, { useState, useEffect, useRef } from 'react';
import { withStyle } from 'baseui';
import { Checkbox } from 'baseui/checkbox';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import { Formik, Form, Field } from 'formik';

import {
    Grid,
    Row as Rows,
    Col as Column,
} from '../../components/FlexBox/FlexBox';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { FormFields, FormLabel, Error } from '../../components/FormFields/FormFields';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';
import PhoneNumberInput from '../../components/PhoneNumberInput/PhoneNumberInput';
import { useHistory } from 'react-router-dom';

const Col = withStyle(Column, () => ({
    '@media only screen and (max-width: 767px)': {
        marginBottom: '20px',

        ':last-child': {
            marginBottom: 0,
        },
    },
}));

const Row = withStyle(Rows, () => ({
    '@media only screen and (min-width: 768px)': {
        alignItems: 'center',
    },
}));
const MyInput = ({ field, form, ...props }) => {
    return <Input {...field} {...props} />;
};


const CHANGE_PHONE_NUMBER = gql`
mutation ChangePhoneNumber($phoneNumber:String!,$password:String!){
  changePhoneNumber(phoneNumber:$phoneNumber,password:$password)
}
`


const schema = () => {
    return Yup.object().shape({
        phoneNumber: Yup.string().required("C'est requis"),
        password: Yup.string().required("C'est requis"),
    });
};

export default function ChangePhoneNumberModal() {
    const [isOpen, setIsOpen] = React.useState(false);
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    
    function close() {
        setIsOpen(false);
    }

    const [changePhone, { loading }] = useMutation(CHANGE_PHONE_NUMBER, {
        onError(error) {
            console.error({ error })
            let errorMessage = (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message) || 'le serveur est temporairement indisponible. Veuillez réessayer ultérieurement'
            errorMessage = errorMessage.split(':')[0]
            enqueueSnackbar(errorMessage, { variant: "error" })
            // history.push("/profile")
        },
        update(_, { data }) {
            if(data.changePhoneNumber){
            enqueueSnackbar('Veuillez entrer votre code de validation', { variant: "success" })
            history.push("/verifyNewPhoneNumber")
            
        }},
    })



    let change = ({ phoneNumber, password }, { setSubmitting }) => {
        console.log({ phoneNumber, password });
        console.log(" test ");

        changePhone({
            variables: {
                phoneNumber,
                password
            }
        })
        // ,
         localStorage.setItem("phoneNumber",phoneNumber)
         
    };


    return (
        <>
            <Button style={{ marginTop: "30px", width:"100%" }} type="button" onClick={() => setIsOpen(true)}>Changer numéro téléphone</Button>
            <Modal onClose={close} isOpen={isOpen}>
                <Formik
                    initialValues={{ phoneNumber: "", password: "" }}
                    onSubmit={change}
                    validationSchema={schema}
                    enableReinitialize
                >
                    {({ values, errors, isSubmitting, isValid,setFieldValue }) => {
                        console.log({ values, errors, isSubmitting, isValid });
                        return (
                            <Form>
                                <React.Fragment>
                                    <ModalBody>
                                        {/* <FormFields>
                                            <FormLabel>Numéro de téléphone</FormLabel>
                                            <Field
                                                type="text"
                                                name="phoneNumber"
                                                component={MyInput}
                                            />
                                        </FormFields> */}
                                        <FormFields>
                     <FormLabel>Votre nouveau numéro de téléphone</FormLabel>
                     <PhoneNumberInput
                       onPhoneNumberChange={(number) => {
                         setFieldValue('phoneNumber', number);
                       }} />
                     
              </FormFields>
                                        <FormFields>
                                            <FormLabel>Entrer votre mot de passe</FormLabel>
                                            <Field
                                                type="password"
                                                name="password"
                                                component={MyInput}
                                            />
                                        </FormFields>
                                        {/* <Button type="submit">Changer</Button> */}
                                    </ModalBody>
                                    <ModalFooter>
                                        
                                        
                                        <ModalButton kind="tertiary" onClick={close} type="button">
                                            Annuler
    </ModalButton>
                                        <Button type="submit">Changer</Button>
                                    </ModalFooter>

                                </React.Fragment>
                            </Form>
                        )
                    }}

                </Formik>
            </Modal>
        </>
    )





}


