import React, { useContext, lazy, Suspense, createContext, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  PRODUCTS,
  CATEGORY,
  DASHBOARD,
  ORDERS,
  SETTINGS,
  CUSTOMERS,
  COUPONS,
  STUFF_MEMBERS,
  SITE_SETTINGS,
  SIGNIN,
  SIGNIN_WITH_PASSWORD,
  SIGNUP,
  PROFILE,
  INVOICES,
  VERIFY_PHONE_NUMBER,
  VERIFY_AND_SET_PASSWORD,
  CHECKOUT,
  RECEIPT,
  HISTORY,
  REGULATIONS,
  BENEFITS,
  RETURN,
  UPDATE_PASSWORD,
  CONFIRM_UPDATE_PASSWORD,
  RELATIONS,
   CHANGE_PHONE_NUMBER
} from "./settings/constants";
import AuthProvider, { AuthContext } from "./context/auth";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
import Receipt from "./containers/Receipt/Receipt";
import confirmNewPassword from "./containers/ConfirmUpdatePassword/confirmNewPassword";
const Products = lazy(() => import("./containers/Products/Products"));
const AdminLayout = lazy(() => import("./containers/Layout/Layout"));
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const Category = lazy(() => import("./containers/Category/Category"));
const Orders = lazy(() => import("./containers/Orders/Orders"));
const History = lazy(() => import("./containers/History/History"));
const Settings = lazy(() => import("./containers/Settings/Settings"));
const SiteSettingForm = lazy(() =>import("./containers/SiteSettingForm/SiteSettingForm"));
const StaffMembers = lazy(() =>
  import("./containers/StaffMembers/StaffMembers")
);
const Customers = lazy(() => import("./containers/Customers/Customers"));
const Coupons = lazy(() => import("./containers/Coupons/Coupons"));
const Login = lazy(() => import("./containers/Login/Login"));
const Signin = lazy(() => import("./containers/Signin/Signin"));
const SigninWithPassword = lazy(() => import("./containers/SigninWithPassword/SigninWithPassword"));
const Signup = lazy(() => import("./containers/Signup/Signup"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));
const Profile = lazy(() => import("./containers/Profile/Profile"));
const Invoices = lazy(() => import("./containers/Invoices/Invoices"));
const VerifyPhoneNumber = lazy(() => import("./containers/VerifyPhoneNumber/VerifyPhoneNumber"));
const VerifyAndSetPassword = lazy(() => import("./containers/VerifyAndSetPassword/VerifyAndSetPassword"));
const Checkout = lazy(() => import("./containers/Checkout/Checkout"));
const Regulations = lazy(() => import("./containers/Regulations/Regulations"));
const Collecting = lazy(() => import("./containers/Collecting/Collecting"));
const ItemsReturns = lazy(() => import("./containers/ItemsReturns/ItemsReturns"));
const UpdatePassword=lazy(()=>import("./containers/VerifyPassword/VerifyPassword"))
const ConfirmNewPassword=lazy(()=>import("./containers/ConfirmUpdatePassword/confirmNewPassword"))
const Relations = lazy(() => import("./containers/Relations/Relations"));
const ChangePhoneNumber=lazy(()=>import("./containers/changePhoneNumber/changePhoneNumber"))



export const ProductsLayoutContext = createContext({endReached: false, setEndReached: null})

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

const Routes = () => {
  const [endReached, setEndReached] = useState(false)
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          {/* <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute> */}

          <PrivateRoute exact={true} path={PRODUCTS}>
          <ProductsLayoutContext.Provider value={{endReached, setEndReached}}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </ProductsLayoutContext.Provider>
          </PrivateRoute>

          <PrivateRoute path={CHECKOUT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Checkout />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={RECEIPT}>
            {/* <AdminLayout> */}
            <Suspense fallback={<InLineLoader />}>
              <Receipt />
            </Suspense>
            {/* </AdminLayout> */}
          </PrivateRoute>

          <PrivateRoute path={CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Category />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Orders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={HISTORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <History />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={RELATIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Relations />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={REGULATIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Regulations />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={RETURN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ItemsReturns />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={BENEFITS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Collecting />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COUPONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupons />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STUFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
           
           <PrivateRoute path={CHANGE_PHONE_NUMBER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
            <ChangePhoneNumber/>
            </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PROFILE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Profile />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVOICES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Invoices />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={SIGNIN}>
            <Signin />
          </Route>
         
         
          <Route path={SIGNIN_WITH_PASSWORD}>
            <SigninWithPassword />
          </Route>
          <Route path={SIGNUP}>
            <Signup />
          </Route>
          <Route path={VERIFY_PHONE_NUMBER}>
            <VerifyPhoneNumber />
          </Route>
          <Route path={VERIFY_AND_SET_PASSWORD}>
            <VerifyAndSetPassword />
          </Route>
          <Route path={UPDATE_PASSWORD}>
            <UpdatePassword/>
            </Route>
            <Route path={CONFIRM_UPDATE_PASSWORD}>
              <ConfirmNewPassword/>
            </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
