export const TOGGLE_PRODUCT_TO_PRINT = "TOGGLE_PRODUCT_TO_PRINT";

const toggleProductToPrint = (product, state) => {
    const productExistsAlready = state.itemsToPrint.find(itemToPrint => itemToPrint.id === product.id)
    if (productExistsAlready) {
        const itemsToPrint = state.itemsToPrint.filter(itemToPrint => itemToPrint.id !== product.id)
        localStorage.setItem("itemsToPrint", JSON.stringify(itemsToPrint)) 
        return {
            ...state,
            itemsToPrint
        }
    }
    const itemsToPrint = [...state.itemsToPrint, product]
    localStorage.setItem("itemsToPrint", JSON.stringify(itemsToPrint)) 
    return {
        ...state,
        itemsToPrint
    }
};


export const printReducer = (state, action) => {
    switch (action.type) {
        case TOGGLE_PRODUCT_TO_PRINT:
            return toggleProductToPrint(action.product, state);
        default:
            return state;
    }
};
