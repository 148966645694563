import React from "react";

export default React.createContext({
  items: [],

  addProductToCart: product => {},
  removeProductFromCart: productId => {},
  toggleProduct: product => {},
  reset: () => {}
});
